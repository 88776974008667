.App {
  text-align: center;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-swiper {
  margin:0vh 0;
  margin-left: 0vh;
}


.desktop-swiper {
  margin:4vh 0;
}

.swiper {
  width: 100%;
}

.desktopSwiper {
  width: 100%;
  margin-left:-65vh;
  padding-right:65vh;

}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #EBEEFF;
  opacity: 1;
  height:6px;
  width:6px;
}

/* target all bullets */
.swiper-pagination-bullet {
  background-color: #B8B1FA;
  opacity: 1;
  height:6px;
  width:6px;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 46vh;
  margin-left: 3vh;
  z-index:0;
}

.desktopSwiper-slide {
  background-position: center;
  background-size: cover;
  width: 50.5vh;
  height: 42vh;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(94,89,155);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(94,89,155);;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(94,89,155);;
}

textarea {
  resize: none;
}

textarea::placeholder {
  color:#B2B5DD
}

textarea:focus { 
  outline: none !important;
  border-color: #A9A0F9;
  box-shadow: 0 0 10px #A9A0F9;
}
h1 {
  font-weight: 400;
  color: #EBEEFF;
  font-family: 'Poppins';
  font-size: 9vh;
  letter-spacing: -0.4vh;
  line-height:12.5vh;
  margin:0px;
}

h2 {
  font-weight: 400;
  color: #EBEEFF;
  font-family: 'Poppins';
  font-size: 4vh;
  text-align: 'center';
  letter-spacing: -0.2vh;
  margin-bottom: 0vh;
}

h3 {
  font-weight: 500;
  color: #030715;
  font-family: 'Poppins';
  font-size: 3vh;
  letter-spacing: -0.1vh;
  margin-bottom:0px;
  margin-top:0px;
}

h4 {
  font-weight: 500;
  color: #B6B8D9;
  font-family: 'Poppins';
  font-size: 2.3vh;
  letter-spacing: -0.1vh;
  margin-bottom:0px;
  margin-top:0px;
}

span {
  background-color: rgba(244,176,255,0.3);
  border-radius: 5vh;
  padding-left:1vh;
  padding-right:1.8vh;
}
button {
  outline: none; 
  border-radius: 1.5vh;
  background-color: #EBEEFF;
  padding-top: 1vh; 
  padding-bottom: 1vh;
  padding-left: 7vh; 
  padding-right: 7vh;
  border: 0;
  font-weight: 500;
  font-family: 'Poppins';
  font-size: 4vh;
  text-align: 'center';
  letter-spacing: -0.13vh;
}

small {
  font-weight: 400;
  color: #EBEEFF;
  font-family: 'Poppins';
  font-size: 2.1vh;
  text-align: 'center';
  letter-spacing: -0.09vh;
  margin:0px;
}
a {
  text-decoration: none;
}

p {
  margin-top:2vh;
  margin-left:0.5vh
}
